import moment from "moment";

const isoDateTimeToFormat = (date) => {
  if (!date) return "00:00";

  date = new Date(date);

  return `${date.toLocaleDateString("en-US")} ${date.toLocaleTimeString(
    "en-US"
  )}`;
};

const utcToLocal = (utcDate) => {
  return moment.utc(utcDate).local();
};

const timeZone = () => {
  return new Intl.DateTimeFormat("en-US", { timeZoneName: "short" })
    .format(new Date())
    .split(" ")[1];
};

export { isoDateTimeToFormat, timeZone, utcToLocal };
