import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { api } from "../../Services/api-service";
import notificationSvc from "../../Services/notification";
import Logo from "../../assets/images/spark-logo.png";
import { FaArrowRightLong } from "react-icons/fa6";
import Login from "../Login/login";
import { GiCheckMark } from "react-icons/gi";
import OAuth from "../../components/OAuth/OAuth";

const Signup = ({ isLoggedIn, onLogin, tab }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    refCode: "",
  });

  const [isLoginMode, setIsLoginMode] = useState(tab !== "login");

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const refCode = queryParams.get("ref");

  useEffect(
    () =>
      setFormData({
        ...formData,
        refCode: refCode,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refCode]
  );

  const register = async (e) => {
    e.preventDefault();
    if (!isTermsAccepted) {
      notificationSvc.error(
        "You must accept the terms and conditions to proceed."
      );
      return;
    }
    try {
      const response = await api.post("users/signup", formData);
      if ((response && response.status === 200) || response.status === 201) {
        notificationSvc.success(response.data.message);
        handleToggleChange();
      }
    } catch (error) {
      notificationSvc.error(error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) navigate("/dashboard");
  }, [isLoggedIn, navigate]);

  const handleToggleChange = () => {
    setIsLoginMode((prevMode) => !prevMode);

    if (isLoginMode) {
      navigate("/login");
    } else {
      navigate("/register");
    }
  };

  return (
    <>
      <div className="h-auto min-h-screen flex flex-col md:flex-row items-center justify-center p-4 md:p-8 bg-slate-200">
        <div className="flex flex-col md:flex-row shadow-custom bg-slate-900 rounded-3xl w-full max-w-6xl p-4 md:p-6">
          {/* Left Section */}
          <div className="flex flex-1 items-end justify-center md:justify-start rounded-3xl bg-slate-200 p-6 md:p-10">
            <div className="text-center md:text-left max-w-md">
              <img
                src={Logo}
                alt="Logo"
                className="mx-auto md:mx-0 mb-6 max-w-[120px]"
              />
              {!isLoginMode ? (
                <>
                  <h2 className="text-slate-900 text-2xl md:text-4xl lg:text-5xl !font-lexendBold mb-3">
                    Welcome back!
                  </h2>
                  <p className="text-slate-900 text-base md:text-lg lg:text-xl !font-lexendRegular">
                    Together, we can achieve great things.
                  </p>
                </>
              ) : (
                <>
                  <h2 className="text-slate-900 text-2xl md:text-4xl lg:text-5xl !font-lexendBold mb-3">
                    Connect, collaborate, and create.
                  </h2>
                  <p className="text-slate-900 text-base md:text-lg lg:text-xl !font-lexendRegular">
                    Your next big project starts here.
                  </p>
                </>
              )}
            </div>
          </div>

          {/* Right Section */}
          <div className="flex-1 p-6 md:p-10">
            {/* Toggle Section */}
            <div className="flex justify-center items-center bg-slate-900 mb-6">
              <div
                onClick={handleToggleChange}
                className="relative inline-flex items-center w-[16rem] h-[3rem] p-1 bg-transparent border border-slate-200 rounded-full cursor-pointer transition-colors duration-200"
              >
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={isLoginMode}
                  readOnly
                />
                <span className="absolute top-1 left-1 w-[calc(50%-4px)] h-[calc(100%-8px)] bg-slate-200 rounded-full transition-transform duration-200 peer-checked:translate-x-full"></span>
                <div className="absolute left-0 w-1/2 h-full flex items-center justify-center">
                  <span
                    className={`text-sm md:text-base !font-lexendBold ${isLoginMode ? "text-slate-200" : "text-spark-700"
                      }`}
                  >
                    Login
                  </span>
                </div>
                <div className="absolute right-0 w-1/2 h-full flex items-center justify-center">
                  <span
                    className={`text-sm md:text-base !font-lexendBold ${isLoginMode ? "text-spark-700" : "text-slate-200"
                      }`}
                  >
                    Sign Up
                  </span>
                </div>
              </div>
            </div>

            {/* Content Section */}
            <div>
              {!isLoginMode ? (
                <Login isLoggedIn={isLoggedIn} onLogin={onLogin} />
              ) : (
                <>
                  <h2 className="text-2xl md:text-3xl text-slate-200 lg:text-4xl !font-lexendBold mb-6 text-center md:text-left">
                    Register to Get Matches
                  </h2>
                  <OAuth />
                  <form
                    className="space-y-6"
                    onSubmit={(e) => {
                      register(e);
                    }}
                  >
                    <div>
                      <label className="block text-sm md:text-base text-slate-200 !font-lexendRegular mb-2">
                        First Name
                      </label>
                      <input
                        type="text"
                        id="first-name"
                        className="w-full p-3 bg-white rounded-md text-black focus:outline-none focus:ring-2 focus:ring-spark-700"
                        value={formData.firstName}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            firstName: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div>
                      <label className="block text-sm md:text-base text-slate-200 !font-lexendRegular mb-2">
                        Last Name
                      </label>
                      <input
                        type="text"
                        id="last-name"
                        className="w-full p-3 bg-white rounded-md text-black focus:outline-none focus:ring-2 focus:ring-spark-700"
                        value={formData.lastName}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            lastName: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div>
                      <label className="block text-sm md:text-base text-slate-200 !font-lexendRegular mb-2">
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="w-full p-3 bg-white rounded-md text-black focus:outline-none focus:ring-2 focus:ring-spark-700"
                        value={formData.email}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            email: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div>
                      <label className="block text-sm md:text-base text-slate-200 !font-lexendRegular mb-2">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        className="w-full p-3 bg-white rounded-md text-black focus:outline-none focus:ring-2 focus:ring-spark-700"
                        value={formData.password}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            password: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        id="terms"
                        className="hidden peer"
                        checked={isTermsAccepted}
                        onChange={(e) => setIsTermsAccepted(e.target.checked)}
                      />

                      <label
                        htmlFor="terms"
                        className="w-6 h-6 flex items-center justify-center text-white border-2 border-spark-700 rounded-md cursor-pointer peer-checked:bg-spark-700 transition-colors duration-200"
                      >
                        {isTermsAccepted && (
                          <GiCheckMark color="white" size={"13px"} />
                        )}
                      </label>
                      <label
                        htmlFor="terms"
                        className="text-sm md:text-base !font-lexendRegular text-slate-200 cursor-pointer"
                      >
                        I accept the Terms and Conditions
                      </label>
                    </div>
                    <button
                      type="submit"
                      className="w-full py-3 flex items-center justify-center bg-spark-700 text-white rounded-md !font-lexendRegular"
                    >
                      Register your Account&nbsp;&nbsp;
                      <FaArrowRightLong />
                    </button>
                  </form>
                </>
              )}
            </div>

            {/* <div className="mt-10 flex flex-wrap justify-center items-center gap-4">
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <GoogleOAuth />
              </GoogleOAuthProvider>
              <LinkedinOAuth />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
