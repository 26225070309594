import React, { useEffect, useRef, useState } from "react";
import Cards from "../../components/cards";
import notificationSvc from "../../Services/notification";
import { api } from "../../Services/api-service";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivePlan } from "../../redux/activePlanSlice";
import DashboardFooter from "../../components/DashboardFooter";
import { fetchAttributes } from "../../redux/attributeSlice";
import { PreferenceHorizontalIcon, Search01Icon } from "hugeicons-react";
import MatchedUserProfile from "../../components/UserProfile/MatchedUserProfile";
import FilterModal from "../../components/Modals/FilterModal";
import { useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import { debounce } from "../../utils/helpers/debounce.helper";

const Dashboard = ({ user }) => {
  const [matches, setMatches] = useState(null);

  const [loading, setLoading] = useState(true);

  const [matchedProfle, setMatchedProfile] = useState(null);

  const [isFilterModalOpen, setFilterModalOpen] = useState(false);

  const [filters, setFilters] = useState({
    country: "",
    state: "",
    city: "",
    interests: null,
    skills: null,
    lookingFor: null,
    scoreRange: null,
    name: "",
  });

  const dispatch = useDispatch();
  const activeplan = useSelector((state) => state.activePlan.activeplan);
  const portalUrl = useSelector((state) => state.portal.url);
  const navigate = useNavigate();

  const profile = async (currentFilters = filters) => {
    let queryParams = new URLSearchParams();

    if (currentFilters.name) {
      queryParams.append("name", currentFilters.name.toString());
    }

    if (currentFilters.country) {
      queryParams.append("country", currentFilters.country.toString());
    }

    if (currentFilters.state) {
      queryParams.append("state", currentFilters.state.toString());
    }

    if (currentFilters.city) {
      queryParams.append("city", currentFilters.city.toString());
    }
    if (currentFilters.interests && currentFilters.interests.length > 0) {
      const interests = currentFilters.interests
        .map((item) => item.value)
        .join(",");
      queryParams.append("interests", interests);
    }

    if (currentFilters.skills && currentFilters.skills.length > 0) {
      const skills = currentFilters.skills.map((item) => item.value).join(",");
      queryParams.append("skills", skills);
    }

    if (currentFilters.scoreRange) {
      queryParams.append(
        "fromScore",
        currentFilters.scoreRange.from?.toString()
      );
      queryParams.append("toScore", currentFilters.scoreRange.to?.toString());
    }

    if (currentFilters.lookingFor) {
      queryParams.append("lookingFor", currentFilters.lookingFor);
    }

    const response = await api.get(
      `users/profiles/list?${queryParams.toString()}`
    );

    if (response && response.status === 200) {
      setMatches(response.data);
    } else {
      setMatches([]);
    }
    setLoading(false);
  };

  const debouncedProfile = useRef(debounce(profile, 500)).current;

  useEffect(() => {
    dispatch(fetchAttributes());
  }, [dispatch]);

  useEffect(() => {
    if (!activeplan) {
      dispatch(fetchActivePlan());
    }

    profile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeplan, dispatch]);

  useEffect(() => {
    if (portalUrl) {
      window.location.href = portalUrl;
    }
  }, [portalUrl]);

  const onProfileButtonClick = (match) => {
    setMatchedProfile(match);
  };

  const onMatchedProfileClose = () => {
    setMatchedProfile(null);
  };

  const makeInteraction = async (e, match) => {
    e.preventDefault();
    const response = await api.post(`users/profiles/${match.id}/interact`);
    if (response && response.status === 200) {
      navigate("/chat", { state: match.user });
      notificationSvc.success("Proceeding successful Interaction");
    }
  };

  return (
    <div className="sm:px-10 px-4">
      <FilterModal
        isModalOpen={isFilterModalOpen}
        setIsModalOpen={setFilterModalOpen}
        onModalClose={() => setFilterModalOpen(false)}
        filters={filters}
        setFilters={setFilters}
        onApplyClick={profile}
      />
      <div className="min-h-[85vh] w-full">
        <p className="rounded-[41px] bg-spark-500 bg-opacity-10 p-[15px] mt-5 text-spark-500  text-[15px] font-[400]">
          This is Spark Beta! Enjoy all the premium features of Spark for free
          until <span className="font-bold">January 31st</span>
        </p>
        {!loading && (
          <div className="pt-4 flex justify-between space-y-2 sm:space-y-0 sm:flex-row flex-col">
            <div className="flex-1">
              <h1 className="text-2xl font-bold">Hi, {user?.firstName}</h1>
              <p className="text-xl font-light">Your Potential Matches</p>
            </div>
            <div className="flex flex-1 items-center justify-end rounded-md">
              <div className="flex items-center border bg-white me-2 rounded-lg p-1 w-full sm:max-w-lg">
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    setFilters({ ...filters, name: e.target.value });
                    debouncedProfile({ ...filters, name: e.target.value });
                  }}
                  className="focus:outline-none focus:border-transparent border-transparent !ring-0 outline-none flex-grow py-0 px-4 text-gray-600 placeholder-spark-text-gray"
                />
                <button className="flex items-center justify-center bg-purple-100 rounded-lg p-2">
                  <Search01Icon
                    className="h-5 w-5 text-purple-600"
                    onClick={() => {
                      profile(filters);
                    }}
                  />
                </button>
              </div>
              <button
                onClick={() => setFilterModalOpen(true)}
                className="flex items-center justify-center bg-gray-100 rounded-md p-3"
              >
                <PreferenceHorizontalIcon className="h-5 w-5 text-gray-600" />
              </button>
            </div>
          </div>
        )}

        <div className="flex w-full mt-3 xl:space-x-2 xl:flex-row flex-col-reverse relative">
          <div
            className={`grid grid-cols-[repeat(auto-fill,minmax(340px,auto))] gap-3 flex-1 h-full`}
          >
            {loading ? (
              <div className="flex items-center justify-center text-center mt-8 ">
                <div className="text-center">
                  <Circles
                    height="40"
                    width="40"
                    color="#b30af2"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                  Looking for Matches...
                </div>
              </div>
            ) : matches?.profiles?.length > 0 ? (
              matches?.profiles.map((profile) => (
                <div key={profile.id}>
                  <Cards
                    match={profile}
                    activePlan={activeplan}
                    onProfileButtonClick={() => onProfileButtonClick(profile)}
                    onMessageButtonClick={(e) => makeInteraction(e, profile)}
                  />
                </div>
              ))
            ) : (
              <div className="col-span-3 text-center mt-8">
                <h2>No matches found</h2>
              </div>
            )}
          </div>

          <MatchedUserProfile
            onMessageButtonClick={(e) => makeInteraction(e, matchedProfle)}
            onMatchedProfileClose={onMatchedProfileClose}
            className={`${
              matchedProfle
                ? "w-full xl:w-[45%] xl:min-w-[500px]"
                : "w-0 xl:min-w-0 !p-0 m-0 !h-0"
            }  overflow-x-hidden fixed top-0 bottom-0 left-0 right-0 z-[1004] xl:z-0 xl:sticky xl:top-20 h-screen xl:max-h-[calc(100vh-80px)] mb-4 xl:mb-0 duration-100 ease-linear`}
            match={matchedProfle}
          />
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default Dashboard;
