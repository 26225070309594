const API_BASE_URL =
  process.env.REACT_APP_BACKEND_URL ?? "https://backend.spark-founders.com";
const GOOGLE_CLIENT_ID =
  "384456812607-4gukbgbtsgrka2b12dbvg9t407udibfc.apps.googleusercontent.com";
const LINKEDIN_CLIENT_ID = "789zp772lhulst";
const STRIPE_PUBLIC_KEY =
  process.env.REACT_APP_STRIPE_KEY ??
  "pk_test_51PT8FYRoudj7ROs3nr0uJsVLa03qjaIS2wWxSgeYJnJaB4RZ4TPuR2shknDCmqG5D3lrHBjJphwMDQYSBcmkufnX00OrMaLy81";

export {
  API_BASE_URL,
  GOOGLE_CLIENT_ID,
  LINKEDIN_CLIENT_ID,
  STRIPE_PUBLIC_KEY,
};
