/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Cards from "../../components/cards";
import { api } from "../../Services/api-service";
import { useDispatch, useSelector } from "react-redux";
import DashboardFooter from "../../components/DashboardFooter";
import { useParams, useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import { fetchEvents } from "../../redux/eventSlice";
import notificationSvc from "../../Services/notification";
import MatchedUserProfile from "../../components/UserProfile/MatchedUserProfile";
import EventMatchesMessage from "./EventMatchesMessage";
import moment from "moment";

const EventMatches = ({ user }) => {
  const { id } = useParams();

  const [matches, setMatches] = useState(null);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const events = useSelector((state) => state.events.events);

  const [matchedProfle, setMatchedProfile] = useState(null);

  const navigate = useNavigate();

  const onProfileButtonClick = (match) => {
    setMatchedProfile(match);
  };

  const onMatchedProfileClose = () => {
    setMatchedProfile(null);
  };

  const makeInteraction = async (e, match) => {
    e.preventDefault();
    const response = await api.post(`users/profiles/${match.id}/interact`);
    if (response && response.status === 200) {
      navigate("/chat", { state: match.user });
      notificationSvc.success("Proceeding successful Interaction");
    }
  };

  useEffect(() => {
    eventMatches();
  }, []);

  useEffect(() => {
    dispatch(fetchEvents());
  }, []);

  const eventMatches = async () => {
    const response = await api.get(`events/matchs/${id}`);
    if (response && response.status === 200) {
      console.log("Matches", response.data);
      setMatches(response.data);
    }
    setLoading(false);
  };

  const event = React.useMemo(() => {
    if (!events || (!events.upcomingEvents && !events.pastEvents)) {
      return "";
    }

    const allEvents = [
      ...(events.upcomingEvents || []),
      ...(events.pastEvents || []),
    ];
    const matchedEvent = allEvents.find((event) => event.id === id);

    return matchedEvent;
  }, [events, id]);

  useEffect(() => {
    eventMatches();
  }, [id]);

  const hasMatchesStarted =
    event?.matchAt != null && moment().isSameOrAfter(event?.matchAt);

  return (
    <div className="sm:px-10 px-4">
      <div className="min-h-[85vh] w-full">
        {loading ? (
          <div className="flex items-center justify-center text-center mt-8 ">
            <div className="text-center">
              <Circles
                height="40"
                width="40"
                color="#b30af2"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              Looking for Matches...
            </div>
          </div>
        ) : (
          <>
            {hasMatchesStarted ? (
              <>
                {!loading && matches?.profiles?.length > 0 && (
                  <div className="pt-4 flex justify-between space-y-2 sm:space-y-0 sm:flex-row flex-col">
                    <div className="mt-6">
                      <h1 className="text-xl font-lexendMedium">
                        {user?.firstName} here are your matches for{" "}
                        {event.title}
                      </h1>
                    </div>
                  </div>
                )}

                <div
                  className={`${
                    matches?.profiles?.length > 0
                      ? "flex w-full mt-6 xl:space-x-2 xl:flex-row flex-col-reverse relative"
                      : "flex flex-col"
                  }`}
                >
                  <div
                    className={`${
                      matches?.profiles.length > 0
                        ? "grid grid-cols-[repeat(auto-fill,minmax(340px,auto))] gap-3 flex-1 h-full"
                        : "flex-1 flex items-center justify-center"
                    }`}
                  >
                    {matches?.profiles?.length > 0 ? (
                      matches?.profiles.map((profile) => (
                        <div key={profile.id}>
                          <Cards
                            match={profile}
                            onProfileButtonClick={() =>
                              onProfileButtonClick(profile)
                            }
                            onMessageButtonClick={(e) =>
                              makeInteraction(e, profile)
                            }
                          />
                        </div>
                      ))
                    ) : (
                      <p>No matches</p>
                    )}
                  </div>

                  <MatchedUserProfile
                    onMessageButtonClick={(e) =>
                      makeInteraction(e, matchedProfle)
                    }
                    onMatchedProfileClose={onMatchedProfileClose}
                    className={`${
                      matchedProfle
                        ? "w-full xl:w-[45%] xl:min-w-[500px]"
                        : "w-0 xl:min-w-0 !p-0 m-0 !h-0"
                    }  overflow-x-hidden fixed top-0 bottom-0 left-0 right-0 z-[1004] xl:z-0 xl:sticky xl:top-20 h-screen xl:max-h-[calc(100vh-80px)] mb-4 xl:mb-0 duration-100 ease-linear`}
                    match={matchedProfle}
                  />
                </div>
              </>
            ) : (
              <div className="flex flex-col">
                <div className="flex-1 flex items-center justify-center">
                  <div className="bg-white w-full h-max mt-8 p-8 flex-1 flex items-center justify-center  rounded-lg">
                    <EventMatchesMessage />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <DashboardFooter />
    </div>
  );
};

export default EventMatches;
