import React from 'react';
import sidermenu from '../assets/images/SiderMenu.png';
import WavesCheck from './icons/WavesCheck';
import { AiBrain04Icon, Brain02Icon, City01Icon, Message02Icon, UserFullViewIcon, UserSharingIcon } from 'hugeicons-react';

const Cards = ({ match, onProfileButtonClick, onMessageButtonClick }) => {
    return (
        <div className='flex flex-col rounded-lg p-7 bg-white h-full justify-between'>
            {/* Badge */}
            <div className='flex justify-between items-center mb-3'>
                <span className='flex items-center bg-spark-500 bg-opacity-10 ps-1 pe-3 py-1 rounded-full text-xs'>
                    <WavesCheck className='me-2' />
                    {match.role}
                </span>
            </div>

            {/* Profile Image and Score */}
            <div className='flex justify-center items-center relative mb-6'>
                <div className='w-fit relative'>
                    <img src={match.user.profilePicture?.publicUrl || sidermenu} alt='Profile' className='w-24 h-24 rounded-full' />
                    <div className='absolute translate-x-full -left-2 bottom-0 bg-spark-bg-gray rounded-lg px-5 min-w-10 py-2'>
                        <p className='leading-none text-xs text-gray-500'>Score</p>
                        <p className='leading-none font-bold text-center'>{match.score}</p>
                    </div>
                </div>
            </div>

            {/* User Name and Role */}
            <div className='text-center mb-4'>
                <h5 className='text-lg font-semibold text-gray-900'>
                    {match.user.firstName} {match.user.lastName}
                </h5>
                <p className='text-sm text-gray-500 break-words'>{match.user.tagline}</p>
            </div>

            <hr />
            {/* Information Grid */}
            <div className='flex flex-row w-full space-x-2 pt-2 mb-4'>
                <div className='w-1/2 px-3'>
                    <span className='text-xs text-spark-text-gray'>City</span>
                    <div className='leading-tight flex text text-sm break-words items-center'>
                        <City01Icon className='me-1 min-w-6' />
                        {match.user.city}
                    </div>
                </div>
                <div className='w-1/2 px-3'>
                    <span className='text-xs font-medium text-spark-text-gray'>Gender</span>
                    <p className='leading-tight flex text-sm break-words items-center'>
                        <UserFullViewIcon className='me-1 min-w-6' />
                        {match.user.selectedPronoun}
                    </p>
                </div>
            </div>
            <hr />
            <div className='flex flex-row w-full space-x-2 pt-2 mb-4'>
                <div className='w-1/2 px-3'>
                    <span className='text-xs text-spark-text-gray'>Skills</span>
                    <p className='leading-tight flex text-sm break-words items-center'>
                        <AiBrain04Icon className='me-1 min-w-6' />
                        {match.skills?.join(', ')}
                    </p>
                </div>
                <div className='w-1/2 px-3'>
                    <span className='text-xs font-medium text-spark-text-gray'>Looking for</span>
                    <p className='leading-tight flex text-sm break-words items-center'>
                        <Brain02Icon className='me-1 min-w-6' />
                        {match.lookingFor}
                    </p>
                </div>
            </div>
            <hr />
            {/* Buttons */}
            <div className='flex space-x-0.5 mt-6'>
                <button
                    onClick={onProfileButtonClick}
                    className='flex justify-center space-x-2 flex-1 py-3 rounded-s-lg bg-spark-bg-primary-light font-medium transition'
                >
                    <UserSharingIcon />
                    <span>Profile</span>
                </button>
                <button onClick={onMessageButtonClick} className='flex justify-center space-x-2 flex-1 py-3 rounded-e-lg text-white bg-spark-primary font-medium transition'>
                    <Message02Icon />
                    <span>Message</span>
                </button>
            </div>
        </div>
    );
};

export default Cards;
